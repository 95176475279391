/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@font-face {
  font-family: "mmcbold";
  src: local("mmcfont"), url(./fonts/mmc-bold.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "mmcmedium";
  src: local("mmcmedium"), url(./fonts/mmc-medium.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "mmcregular";
  src: local("mmcregular"), url(./fonts/mmc-regular.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "NeueHaasGroteskDisplay";
  src: url(./fonts/font.woff) format("woff");
}

:root {
  --body-bg: white;
  overflow: hidden;
}

/* main {
  padding-bottom: 32px !important;
} */

body {
  margin: 0;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  /* font-family: "mmcregular", sans-serif; */
  /* font-family: 'mmc-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f3f4f6; */
  background-color: var(--body-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .responsive-hide {
    display: none;
  }
}

.ag-root-wrapper {
  min-height: 400px;
}

.ag-header-cell-label {
  justify-content: center !important;
}

.ag-custom-cell {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ag-leftalign-custom-cell {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.ag-lineheight {
  line-height: 25px !important;
}

.ag-center-cols-container {
  width: 5000px !important;
}

.my-renderer {
  display: flex;
  justify-content: center;
  height: 38px;
  margin: 0 auto;
  width: 38px;
}

.m4 {
  margin: 8px;
}

.ag-right-aligned-header > .ag-cell-label-container > .ag-header-cell-label {
  /* background-color: aqua !important; */
  flex: none !important;
}

.fa-bars {
  font-size: 25px !important;
}

iframe[style*="z-index: 2147483647"] {
  display: none !important;
}

.sbl-circ-path {
  height: 48px;
  width: 48px;
  color: #FFF;
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  border-right-color: #5a5a5a;
  animation: rotate 1s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.force-select {
  -webkit-user-select: all;  /* Chrome 49+ */
  -moz-user-select: all;     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */
}

.ag-paging-panel{
  justify-content: flex-start !important;
}